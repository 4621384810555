import React, { useState } from "react";
// mui
import { Typography, Box, Stack } from "@mui/material";
// carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
// components
import Title from "./Title";
import Paragraph from "./Paragraph";
import bookapp3 from "../assets/glamberry11.jpg";
import bookapp4 from "../assets/glamberry12.jpg";
import bookapp5 from "../assets/glamberry13.jpg";
import bookapp9 from "../assets/glamberry14.jpg";
import bookapp10 from "../assets/glamberry15.jpg";
import "./styles.css";

const GallerySalon = () => {
  const [currentIndex, setCurrentIndex] = useState();

  const imageData = [
    {
      alt: "image1",
      url: bookapp3,
    },
    {
      alt: "image2",
      url: bookapp4,
    },
    {
      alt: "image3",
      url: bookapp5,
    },
    {
      alt: "image4",
      url: bookapp9,
    },
    {
      alt: "image5",
      url: bookapp10,
    },
  ];

  const renderSlides = imageData.map((image) => (
    <div key={image.alt}>
      <img src={image.url} alt={image.alt} className="renderSlide" />
    </div>
  ));

  const handleChange = (index) => {
    setCurrentIndex(index);
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        width: "100%",
        backgroundColor: "#fbf7eb",
        alignItems: "center"
      }}
    >
      <Title text={"Gallery"} alignSelf="center" textAlign="center" />
      <div style={{ marginTop: "20px"}} />
      <Carousel
        centerSlidePercentage={40}
        thumbWidth={180}
        dynamicHeight={false}
        centerMode={false}
        showArrows={true}
        infiniteLoop={true}
        selectedItem={imageData[currentIndex]}
        onChange={handleChange}
        className="carousel-container"
        autoPlay={true}
      >
        {renderSlides}
      </Carousel>
    </Box>
  );
};

export default GallerySalon;
