import React from "react";
import {
  Grid,
  Typography,
  IconButton,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";
// icons
import servicelanding1 from "../assets/salonfacial.jpg";
import servicelanding2 from "../assets/salonfemale2.jpg";

// components
import Title from "./Title";
import Paragraph from "./Paragraph";

const ServiceLanding = () => {
  return (
    <Grid
      container
      spacing={0}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        paddingTop: 10,
        px: 2,
        minHeight: "500px",
        backgroundColor: "#fbf7eb",
      }}
    >
      {/* Left Component */}
      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        component="section"
        sx={{ margin: "0 20px" }}
      >
        <Title text={"Women"} textAlign={"start"} />
        <Paragraph
          text={
            " Experience tailored beauty solutions at our salon, from hair services like Hair Wash & Setting to precision treatments like Threading. Renew your glow with our Cleanup and Facial services, leaving your skin radiant. Trust our expert stylists and therapists for a blend of luxury and care, ensuring you feel confident and beautiful."
          }
          maxWidth={"90%"}
          mx={0}
          textAlign={"justify"}
        />
        <Button
          size="large"
          sx={{
            fontSize: "0.9rem",
            textTransform: "none !important",
            py: 2,
            px: 4,
            mt: 6,
            mb: 2,
            borderRadius: 2,
            color: "white",
            minWidth: "90%",
            backgroundColor: "#020100",
            "&:hover": {
              backgroundColor: "#040200",
            },
          }}
          onClick={() => {
            let url = `https://api.whatsapp.com/send/?phone=919972839766`;
            window.open(url);
          }}
        >
          Contact us to know more
        </Button>
      </Grid>

      {/* Right Component */}
      <Grid item xs={12} sm={6} md={3} sx={{ minHeight: "100%" }}>
        <Card
          square={true}
          sx={{
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            // border: "1px solid #ccc",
          }}
        >
          <CardContent>
            <CardMedia
              component="img"
              // height="auto"
              image={servicelanding1}
              alt="CardMedia"
              sx={{ width: "100%", minHeight: "100%" }}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3} sx={{ minHeight: "100%" }}>
        <Card
          square={true}
          sx={{
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            // border: "1px solid #ccc",
          }}
        >
          <CardContent>
            <CardMedia
              component="img"
              // height="auto"
              image={servicelanding2}
              alt="CardMedia"
              sx={{ width: "100%", minHeight: "100%" }}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ServiceLanding;
