import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import pexels5 from '../assets/pexels5.jpg'
import pexels8 from '../assets/pexels8.jpg'
import pexels9 from '../assets/9.jpg';
import pexels3 from '../assets/pexels3.jpg'
import ServiceCard from './ServiceCard'; 

const ServiceDetail = () => {
  const { serviceId } = useParams(); 

  const services = {
    hairService: {
      imageUrl: pexels5,
      title: 'Hair Service',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    hairWash: {
      imageUrl: pexels9,
      title: 'Hair Wash & Setting',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    hairSpa: {
      imageUrl: pexels3,
      title: 'Hair Spa & Treatment',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
  };
  const service = services[serviceId];

  if (!service) {
    return <div>Service not found</div>;
  }

  return (
    <div>
      <ServiceCard
        imageUrl={service.imageUrl}
        title={service.title}
        description={service.description}
      />
    </div>
  );
};

export default ServiceDetail;
