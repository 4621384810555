import React from "react";
import { useNavigate } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import LinkIcon from '@mui/icons-material/Link';
import "./bottomBar.css";

const BottomBar = () => {
  const navigate = useNavigate();

  return (
    <div className="bottomBar">
      <div className="bottomSocial">
        <a
          target="_blank"
          // href="https://www.instagram.com/warrantyme/?hl=en"
          className="extLinkIcon"
        >
          <InstagramIcon />
        </a>
        <a
          target="_blank"
          // href="https://m.facebook.com/WarrantyMe/"
          className="extLinkIcon"
        >
          <FacebookIcon />
        </a>
        <a
          target="_blank"
          // href="https://mobile.twitter.com/mewarranty"
          className="extLinkIcon"
        >
          <TwitterIcon />
        </a>
      </div>
      <div className="contactDetails">
        <p>
          Email: <span className="cdCapital">glamberry2023@gmail.com</span> |
          Telephone: <span className="cdCapital">9972839766 </span> |{" "}
          <span
            onClick={() => {
              navigate("/privacy-policy");
              window.scrollTo(0, 0);
            }}
          >
            Privacy Policy
          </span>
        </p>
        <LinkIcon onClick={() => {
              navigate("/privacy-policy");
              window.scrollTo(0, 0);
            }}/>
      </div>
      <div className="hrLine">
        <hr className="lineWidth"></hr>
      </div>
      <div className="copyrightLine">
        <p className="copyrightText">
          Copyright © 2023 Glamberry. All Rights Reserved. I Terms of Use I
          Privacy Policy I EULA
        </p>
      </div>
    </div>
  );
};

export default BottomBar;
