import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const services = {
  Women: {
    "Hair Service": [
      "French Cut",
      "Kid Haircut (below 10 years)",
      "Hair Cut",
      "Advanced Hair Cut",
      "Split Ends",
      "Hair Wash & Setting",
      "Hair Wash",
      "Blow Dry (without wash)",
      "Blow Dry (with wash)",
      "Treatment (hair wash & conditioning)",
      "Temporary Iron/Tongs",
      "Hair Spa & Treatment",
      "Deep Conditioning",
      "Basic",
      "Scalp Treatment",
      "Hair Loss Treatment",
      "Anti-Dandruff Treatment",
      "Pro-Keratin",
      "Gold Mask",
      "Hair Treatment",
      "Smoothing",
      "Straightening",
      "Rebonding",
      "Keratin",
    ],
    "Hair Colour": [
      "Per Streaks Ammonia",
      "Per Streaks Ammonia Free",
      "Root Touch Up",
      "Global Fashion Colour",
      "Global Fashion Colour (below shoulder)",
      "Global Fashion Colour (extra long)",
      "Global Fashion Highlights",
      "Creative Highlights",
    ],
    Massage: [
      "Head & Massage",
      "Basic Oil Massage (without wash)",
      "Basic Oil Massage (with wash)",
      "Special Oil Massage (without wash)",
      "Special Oil Massage (with wash)",
    ],
    Threading: [
      "Eyebrows",
      "Forehead",
      "Upper Lip",
      "Lower Lip",
      "Chin",
      "Lower Chin",
      "Sides",
      "Full Face",
    ],
    "De-Tan": [
      "Raaga/Dr. Rashel",
      "Raaga Charcoal",
      "03+",
      "03+ Peel Off Mask",
    ],
    "Bleach and De-Tan": [
      "Bleach",
      "De-Tan",
      "Under Arms",
      "Neck",
      "Face",
      "Full Arms",
      "Half Arms",
      "Full Legs",
      "Half Legs",
      "Back & Front",
      "Feet",
      "Full Body",
    ],
    Cleanup: ["Dr. Rashel/Raaga", "Lotus", "03+"],
    "Basic Facial": ["Dr. Rashel/Raaga", "Lotus", "03+", "Lotus Gold Shine"],
    "Advance Facial": [
      "Lotus Instant Fair",
      "Lotus 4 Layer",
      "Lotus Preservita",
      "03+ Crystal Diamond",
    ],
    Waxing: [
      "Normal",
      "Premium",
      "Brazilian",
      "Upper Lip",
      "Lower Lip",
      "Chin",
      "Sides",
      "Feet",
      "Under Arms",
      "Full Arms",
      "Full Legs",
      "Half Legs",
      "Half Arms",
      "Bikini Line",
      "Front and Back",
      "Bikini",
      "Full Body",
      "Full Face",
    ],
    "Body Scrub": ["Full Arms", "Full Legs", "Back & Front", "Full Body"],
    "Body Polishing": ["Full Arms", "Full Legs", "Back & Front", "Full Body"],
    Manicure: [
      "Cut and File",
      "Regular",
      "Lotus",
      "Crystal Spa",
      "O3+ Pedilogix",
      "Paraffin",
      "U.S.A",
    ],
    Pedicure: [
      "Cut and File",
      "Peditrix",
      "Regular",
      "Lotus",
      "Crystal Spa",
      "O3+ Pedilogix",
      "Paraffin",
      "U.S.A Tan-Go",
    ],
  },
  Men: {
    Hair: [
      "Hair Cut",
      "Beard Shape",
      "Beard Trim",
      "Shaving",
      "Kids Hair Cut",
      "Styling with Wash",
      "Hair Spa",
      "Scalp Treatment",
      "Anti-Dandruff Treatment",
      "Hair Loss Treatment",
      "Basic Oil Massage (without wash)",
      "Special Oil Massage (with wash)",
    ],
    "Hair Color": [
      "Hair Color (ammonia)",
      "Hair Color (ammonia free)",
      "Beard Color",
      "Mustache",
      "Highlight Per Streaks",
      "Highlight",
      "Fashion Color",
    ],
    "Hair Treatment": ["Smoothing", "Keratin"],
    "Clean-Up": ["Dr. Rashel", "Raaga Lotus", "O3+"],
    "Basic Facial": ["Dr. Rashel", "Raaga Lotus", "O3+", "Lotus Gold Shine"],
    "Advance Facial": [
      "Lotus Instant Fair",
      "Lotus 4 Layer",
      "Lotus Preservita",
      "03+ Crystal Diamond",
    ],
    "De-Tan": ["Raaga", "Raaga Charcoal", "O3+ Detan", "O3+ Peel Off Mask"],
    Pedicure: [
      "Cut & File",
      "Peditrix",
      "Regular",
      "Lotus",
      "Crystal Spa",
      "O3+ Pedilogix",
      "Paraffin",
      "U.S.A Tan-Go",
    ],
    Waxing: [
      "Under Arms",
      "Half Arms",
      "Half Legs",
      "Full Arms",
      "Full Legs",
      "Front and Back Full",
      "Body",
      "Ear Brazilian",
      "Nose Brazilian",
      "Side Cheeks Brazilian",
      "Full Face Brazilian",
    ],
    "Body Scrub": ["Full Arms", "Full Legs", "Back and Front", "Full Body"],
    "Body Polishing": ["Full Arms", "Full Legs", "Back and Front", "Full Body"],
    "Bleach and De-Tan": [
      "Bleach",
      "De-Tan",
      "Underarms",
      "Neck",
      "Face",
      "Full Arms",
      "Full Legs",
      "Back and Front",
      "Feet",
      "Full Body",
    ],
    Manicure: [
      "Cut & File",
      "Regular",
      "Lotus",
      "Crystal Spa",
      "O3+ Pedilogix",
      "Paraffin",
      "U.S.A",
    ],
  },
  Combo: {
    Combo1: ["Haircut + Pedicure + Head Massage"],
    Combo2: [
      "Haircut + Hair Wash With Blow Dry + Under Arms (Waxing) + Half Legs (Waxing)",
    ],
    Combo3: ["Hair Spa + Haircut + Eyebrows"],
    Combo4: [
      "D-Tan + Full Arms (Waxing) + Half Legs (Waxing) + Raaga Facial + Eyebrows + Upper Lip (Comp. Nail Pant)",
    ],
    Combo5: ["D-Tan Full Arms, Full Legs, Face & Neck + Head Massage"],
    Combo6: ["Haircut + Global Basic Colour"],
    Combo7: ["Dandruff Treatment + 6 Settings"],
    Combo8: ["Global Fashion Hair Colour + Keratin"],
    "Silver Combo": [
      "Haircut + D-Tan + Eyebrow + Upper Lip + Full Arms Honey Wax",
    ],
    "Gold Combo": [
      "Raga Cleanup + Full Arms (Premium) + Half Legs (Regular) + Pedicure + Under Arms + Eyebrow + Upper Lip + Forehead",
    ],
    "Platinum Combo": [
      "Haircut + Hair Spa + Manicure (Lotus) + Pedicure (Lotus) + Facial (O3+) + Full Arms (Premium)",
    ],
    "Special Combo": [
      "Full Arms + Full Legs + Under Arms + Premium Manicure (Crystal Spa) + Pedicure (Crystal Spa) + Facial (Lotus Preservira) + D-Tan + Back & Front Body Polishing + Eyebrow Upper Lip + Forehead",
    ],
  },
};

const ServiceModal = ({ open, handleOpen, handleClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : 400,
    bgcolor: "black",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    maxHeight: "60vh",
    overflowY: "auto",
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{color: "#EBD290"}}>
          Glamberry Menu
        </Typography>
        <List>
          {Object.keys(services).map((category) => (
            <React.Fragment key={category}>
              <ListItem>
                <Typography variant="h6" sx={{color: "#EBD290"}}>{category}</Typography>
              </ListItem>
              {Object.keys(services[category]).map((subCategory) => (
                <React.Fragment key={subCategory}>
                  <ListItem>
                    <Typography variant="subtitle1"  sx={{color: "#EBD290"}}>{subCategory}</Typography>
                  </ListItem>
                  {Array.isArray(services[category][subCategory])
                    ? services[category][subCategory].map((service, index) => (
                        <ListItem key={index} sx={{ pl: 4 }}>
                          <ListItemText primary={service}  sx={{color: "white"}}/>
                        </ListItem>
                      ))
                    : Object.keys(services[category][subCategory]).map(
                        (service, index) => (
                          <ListItem key={index} sx={{ pl: 4 }}>
                            <ListItemText
                              primary={services[category][subCategory][service]}
                              sx={{color: "white"}}
                            />
                          </ListItem>
                        )
                      )}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Modal>
  );
};

export default ServiceModal;
