import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  List,
  ListItem,
  Typography,
  styled,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import logo from "../assets/glamberry10.png";
// import logo2 from "../assets/glamberrylogo.png";
import DrawerItem from "./DrawerItem";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import "./styles.css";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "flex-start",
  "@media (max-width: 768px)": {
    display: "none",
    marginBottom: "10px",
  },
});

const ListMenu = styled(List)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: "20px",
    paddingTop: 40,
  },
}));

const LogoContainer = styled("div")({
  width: 375,
  height: 84,
  display: "flex",
  alignItems: "center",
});

const LogoResContainer = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "40px",
  "@media (min-width: 768px)": {
    display: "none",
  },
});

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  marginLeft: "10px",
  minHeight: 67,
}));

const itemList = [
  {
    text: "Home",
    to: "/home",
  },
  {
    text: "Women",
    to: "/women",
    submenu: [
      { text: "Hair Service", to: "/services/hairService" },
      { text: "Hair Wash & Setting", to: "/services/hairWash" },
      { text: "Hair Spa & Treatment", to: "/services/hairSpa" },
      { text: "Hair Color", to: "/services/hair-color" },
      { text: "Threading", to: "/services/threading" },
      { text: "Detan", to: "/services/detan" },
      { text: "Bleach & Detan", to: "/services/bleach" },
      { text: "Cleanup", to: "/services/cleanup" },
      { text: "Basic Facial", to: "/services/basic-facial" },
      { text: "Advanced Facial", to: "/services/advanced-facial" },
      { text: "Body Scrub", to: "/services/body-scrub" },
      { text: "Body Polishing", to: "/services/body-polishing" },
    ],
  },
  {
    text: "Men",
    to: "/men",
    submenu: [
      { text: "Hair Spa", to: "/services/hair-styling" },
      { text: "Hair Color", to: "/services/hair-color" },
      { text: "Hair Treatment", to: "/services/hair-treatment" },
      { text: "Cleanup", to: "/services/cleanup" },
      { text: "Basic Facial", to: "/services/basic-facial" },
      { text: "Advanced Facial", to: "/services/advanced-facial" },
      { text: "Detan", to: "/services/detan" },
      { text: "Pedicure", to: "/services/pedicure" },
      { text: "Waxing", to: "/services/waxing" },
      { text: "Body Scrub", to: "/services/body-scrub" },
      { text: "Body Polishing", to: "/services/body-polishing" },
      { text: "Bleach and Detan", to: "/services/bleach" },
      { text: "Hair Service", to: "/services/hair-service" },
      { text: "Head Massage", to: "/services/head-massage" },
    ],
  },
  // {
  //   text: "Gallery",
  //   to: "/aboutus",
  //   submenu: [
  //     { text: "Haircut + Pedicure + HeadMassage", to: "/services/hairstyling" },
  //     {
  //       text: "Haircut + HairWashWithBlowDry + UnderArms(Waxing) + HalfLegs(Waxing)",
  //       to: "/services/skincare",
  //     },
  //     { text: "HairSpa+Haircut + Eyebrows", to: "/services/makeup" },
  //     {
  //       text: "D-tan+FullArms(Waxing) + HalfLegs(Waxing) + RaagaFacial + Eyebrows + Upperlip(Comp. Nail Pant)",
  //       to: "/services/hairstyling",
  //     },
  //     {
  //       text: "Haircut+HairWashWithBlowDry+UnderArms(Waxing)+HalfLegs(Waxing)",
  //       to: "/services/skincare",
  //     },
  //     {
  //       text: "D-TanFullArms, FullLegs, Face&Neck+Head Massage",
  //       to: "/services/makeup",
  //     },
  //     { text: "Haircut+GlobalBasicColour", to: "/services/hairstyling" },
  //     { text: "DandruffTreatment+6Settings", to: "/services/skincare" },
  //     { text: "GlobalFashionHairColour+Keratin", to: "/services/makeup" },
  //     {
  //       text: "Haircut+d-tan+eyebrow+upperlip+fullarmshoneywax",
  //       to: "/services/hairstyling",
  //     },
  //     {
  //       text: "RagaCleanup+fullarms(premium)+halflegs(regular)+pedicure+underarms+eyebrow+upperlip+forehead",
  //       to: "/services/skincare",
  //     },
  //     {
  //       text: "Haircut+hairspa+manicure(lotus)+pedicure(lotus)+facial(o3+)+fullarms(premium)",
  //       to: "/services/makeup",
  //     },
  //     { text: "Haircut+Pedicure+HeadMassage", to: "/services/hairstyling" },
  //     {
  //       text: "fullarms+fulllegs+underarms+premiummanicure(crystalspa)+pedicure(crystalspa)+facial(lotusPreservira)+d-tan+back&frontbodypolishing+eyebrowUpperlip+forehead",
  //       to: "/services/skincare",
  //     },
  //     { text: "Makeup", to: "/services/makeup" },
  //   ],
  // },
  // {
  //   text: "About",
  //   to: "/aboutus",
  // },
  {
    text: "Contact",
    to: "/contact",
  },
];

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(
    Array(itemList.length).fill(null)
  );

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  const handleMenuOpen = (index, event) => {
    const newAnchorEl = [...submenuAnchorEl];
    newAnchorEl[index] = event.currentTarget;
    setSubmenuAnchorEl(newAnchorEl);
  };

  const handleMenuClose = (index) => {
    const newAnchorEl = [...submenuAnchorEl];
    newAnchorEl[index] = null;
    setSubmenuAnchorEl(newAnchorEl);
  };

  return (
    <AppBar
      sx={{
        // backgroundColor: scrolled ? "black" : "transparent",
        backgroundColor: "black",
      }}
      color="transparent"
      elevation={0}
    >
      <LogoResContainer>
        <img
          // src={scrolled ? logo2 : logo}
          src={logo}
          alt="Glamberry"
          style={{ minWidth: 375, minHeight: 84 }}
        />
      </LogoResContainer>
      <StyledToolbar>
        <LogoContainer>
          <img
            // src={scrolled ? logo2 : logo}
            src={logo}
            alt="Glamberry"
            style={{ minWidth: "100%", minHeight: "100%" }}
          />
        </LogoContainer>
        <ListMenu>
          {itemList.map((item, index) => {
            const { text, to, submenu } = item;
            return (
              <ListItemStyled key={text}>
                <ListItemButton
                  component={NavLink}
                  to={to}
                  sx={{
                    color: "#1f1e1c",
                    minWidth: "80px",
                    maxWidth: "80px",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#1e2a5a",
                    },
                  }}
                >
                  <ListItemText
                    primary={text}
                    sx={{ color: "white" }}
                    className="listItem"
                  />
                </ListItemButton>
              </ListItemStyled>
            );
          })}
        </ListMenu>
      </StyledToolbar>
      {/* <Box
        sx={{
          display: { xs: "block", sm: "none" },
          marginTop: { xs: "20px", sm: 0 },
        }}
      >
        <DrawerItem />
      </Box> */}
    </AppBar>
  );
};

export default Navbar;
