import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
export default function Location() {
  return (
    <Box sx={{ width: "100vw", height: "auto", marginTop: "100px" }}>
      <Box mt={4}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.0116907657907!2d77.67911557507648!3d12.971103587344244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae13b6b98d4cbf%3A0xb788620b6c0fb6d!2sGlam%20Berry%2Cfamily%20salon%20%26%20Makeover%20studio%20Vignan%20nagar!5e0!3m2!1sen!2sin!4v1711515471347!5m2!1sen!2sin"
          width="60%"
          height="700"
          loading="lazy"
        ></iframe>
      </Box>
    </Box>
  );
}