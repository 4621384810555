import React from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  styled,
} from '@mui/material';


const StyledContainer = styled(Container)({
  paddingTop: '10rem',
  paddingBottom: '2rem',
});

const StyledCard = styled(Card)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const AboutPage = () => {
  return (
    <StyledContainer maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        About Our Salon Boutique
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Typography variant="h5" component="h2">
                Our Story
              </Typography>
              <Typography variant="body2" component="p">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat.
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Typography variant="h5" component="h2">
                Unique Selling Proposition
              </Typography>
              <Typography variant="body2" component="p">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat.
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>
        {/* Repeat similar card components for other sections */}
      </Grid>
    </StyledContainer>
  );
};

export default AboutPage;
