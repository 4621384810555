import React, { useState } from "react";
import {
  styled,
  useTheme,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Collapse,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import PhotoFilterIcon from "@mui/icons-material/PhotoFilter";
import "./styles.css";

const drawerWidth = 240;

const itemList = [
  {
    text: "Home",
    icon: <HomeIcon />,
    to: "/home",
  },
  {
    text: "Women",
    icon: <PhotoFilterIcon />,
    to: "/services",
    submenu: [
      { text: "Hair Service", to: "/services/hairService" },
      { text: "Hair Wash & Setting", to: "/services/hairWash" },
      { text: "Hair Spa & Treatment", to: "/services/hairSpa" },
      { text: "Hair Color", to: "/services/hair-color" },
      { text: "Threading", to: "/services/threading" },
      { text: "Detan", to: "/services/detan" },
      { text: "Bleach & Detan", to: "/services/bleach" },
      { text: "Cleanup", to: "/services/cleanup" },
      { text: "Basic Facial", to: "/services/basic-facial" },
      { text: "Advanced Facial", to: "/services/advanced-facial" },
      { text: "Body Scrub", to: "/services/body-scrub" },
      { text: "Body Polishing", to: "/services/body-polishing" },
    ],
  },
  {
    text: "Men",
    icon: <AutoFixHighIcon />,
    to: "/services",
    submenu: [
      { text: "Hair Spa", to: "/services/hair-styling" },
      { text: "Hair Color", to: "/services/hair-color" },
      { text: "Hair Treatment", to: "/services/hair-treatment" },
      { text: "Cleanup", to: "/services/cleanup" },
      { text: "Basic Facial", to: "/services/basic-facial" },
      { text: "Advanced Facial", to: "/services/advanced-facial" },
      { text: "Detan", to: "/services/detan" },
      { text: "Pedicure", to: "/services/pedicure" },
      { text: "Waxing", to: "/services/waxing" },
      { text: "Body Scrub", to: "/services/body-scrub" },
      { text: "Body Polishing", to: "/services/body-polishing" },
      { text: "Bleach and Detan", to: "/services/bleach" },
      { text: "Hair Service", to: "/services/hair-service" },
      { text: "Head Massage", to: "/services/head-massage" },
    ],
  },
  {
    text: "Combo",
    icon: <PhotoFilterIcon />,
    to: "/services",
    submenu: [
      { text: "Haircut + Pedicure + HeadMassage", to: "/services/hairstyling" },
      { text: "Haircut + HairWashWithBlowDry + UnderArms(Waxing) + HalfLegs(Waxing)", to: "/services/skincare" },
      { text: "HairSpa+Haircut + Eyebrows", to: "/services/makeup" },
      { text: "D-tan+FullArms(Waxing) + HalfLegs(Waxing) + RaagaFacial + Eyebrows + Upperlip(Comp. Nail Pant)", to: "/services/hairstyling" },
      { text: "Haircut+HairWashWithBlowDry+UnderArms(Waxing)+HalfLegs(Waxing)", to: "/services/skincare" },
      { text: "D-TanFullArms, FullLegs, Face&Neck+Head Massage", to: "/services/makeup" },
      { text: "Haircut+GlobalBasicColour", to: "/services/hairstyling" },
      { text: "DandruffTreatment+6Settings", to: "/services/skincare" },
      { text: "GlobalFashionHairColour+Keratin", to: "/services/makeup" },
      { text: "Haircut+d-tan+eyebrow+upperlip+fullarmshoneywax", to: "/services/hairstyling" },
      { text: "RagaCleanup+fullarms(premium)+halflegs(regular)+pedicure+underarms+eyebrow+upperlip+forehead", to: "/services/skincare" },
      { text: "Haircut+hairspa+manicure(lotus)+pedicure(lotus)+facial(o3+)+fullarms(premium)", to: "/services/makeup" },
      { text: "Haircut+Pedicure+HeadMassage", to: "/services/hairstyling" },
      { text: "fullarms+fulllegs+underarms+premiummanicure(crystalspa)+pedicure(crystalspa)+facial(lotusPreservira)+d-tan+back&frontbodypolishing+eyebrowUpperlip+forehead", to: "/services/skincare" },
      { text: "Makeup", to: "/services/makeup" },
    ],
  },
  {
    text: "About",
    icon: <InfoIcon />,
    to: "/aboutus",
  },
  {
    text: "Contact",
    icon: <ContactMailIcon />,
    to: "/contact",
  },
];

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 5),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const DrawerItem = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(
    Array(itemList.length).fill(false)
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setSubmenuOpen(Array(itemList.length).fill(false));
  };

  const handleClick = (index) => {
    const newSubmenuOpen = [...submenuOpen];
    newSubmenuOpen[index] = !newSubmenuOpen[index];
    setSubmenuOpen(newSubmenuOpen);
  };

  return (
    <>
      <IconButton
        color="black"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerOpen}
        sx={{ ...(open && { display: "none" }) }}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        sx={{
          flexGrow: 1,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {itemList?.map((item, index) => {
            const { text, submenu } = item;
            return (
              <div key={text}>
                <ListItemButton
                  component={Link}
                  to={item.to}
                  // onClick={() => (submenu ? handleClick(index) : handleDrawerClose())}
                >
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={text} className="listItem"/>
                  {submenu && (submenuOpen[index] ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
                {submenu && (
                  <Collapse in={submenuOpen[index]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {submenu.map((subitem, subIndex) => (
                        <ListItemButton
                          key={subitem.text}
                          component={Link}
                          to={subitem.to}
                          sx={{ pl: 4 }}
                          className="listItem"
                        >
                          <ListItemIcon>
                            {/* Add submenu item icon here */}
                          </ListItemIcon>
                          <ListItemText primary={subitem.text} className="listItem"/>
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </div>
            );
          })}
        </List>
      </Drawer>
    </>
  );
};

export default DrawerItem;
