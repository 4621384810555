import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const services = {
  Men: {
    Hair: [
      "Hair Cut",
      "Beard Shape",
      "Beard Trim",
      "Shaving",
      "Kids Hair Cut",
      "Styling with Wash",
      "Hair Spa",
      "Scalp Treatment",
      "Anti-Dandruff Treatment",
      "Hair Loss Treatment",
      "Basic Oil Massage (without wash)",
      "Special Oil Massage (with wash)",
    ],
    "Hair Color": [
      "Hair Color (ammonia)",
      "Hair Color (ammonia free)",
      "Beard Color",
      "Mustache",
      "Highlight Per Streaks",
      "Highlight",
      "Fashion Color",
    ],
    "Hair Treatment": ["Smoothing", "Keratin"],
    "Clean-Up": ["Dr. Rashel", "Raaga Lotus", "O3+"],
    "Basic Facial": ["Dr. Rashel", "Raaga Lotus", "O3+", "Lotus Gold Shine"],
    "Advance Facial": [
      "Lotus Instant Fair",
      "Lotus 4 Layer",
      "Lotus Preservita",
      "03+ Crystal Diamond",
    ],
    "De-Tan": ["Raaga", "Raaga Charcoal", "O3+ De-Tan", "O3+ Peel Off Mask"],
    Pedicure: [
      "Cut & File",
      "Peditrix",
      "Regular",
      "Lotus",
      "Crystal Spa",
      "O3+ Pedilogix",
      "Paraffin",
      "USA Tan-Go",
    ],
    Waxing: [
      "Under Arms",
      "Half Arms",
      "Half Legs",
      "Full Arms",
      "Full Legs",
      "Front and Back",
      "Full Body",
      "Ear Brazilian",
      "Nose Brazilian",
      "Side Cheeks Brazilian",
      "Full Face Brazilian",
    ],
    "Body Scrub": ["Full Arms", "Full Legs", "Back and Front", "Full Body"],
    "Body Polishing": ["Full Arms", "Full Legs", "Back and Front", "Full Body"],
    "Bleach and De-Tan": [
      "Bleach",
      "De-Tan",
      "Underarms",
      "Neck",
      "Face",
      "Full Arms",
      "Full Legs",
      "Back and Front",
      "Feet",
      "Full Body",
    ],
    Manicure: [
      "Cut & File",
      "Regular",
      "Lotus",
      "Crystal Spa",
      "O3+ Pedilogix",
      "Paraffin",
      "U.S.A",
    ],
    "Hair Service": [
      "Hair Cut",
      "Beard Shape",
      "Beard Trim",
      "Shaving",
      "Kids Haircut",
      "Styling with Wash",
      "Hair Spa",
      "Scalp Treatment",
      "Anti-Dandruff Treatment",
      "Hair Loss Treatment",
      "Keratin Spa",
      "Head Massage",
      "Basic Oil Massage (without wash)",
      "Basic Oil Massage (with wash)",
      "Special Oil Massage (without wash)",
      "Special Oil Massage (with wash)",
    ],
  },
};

const MenServices = () => {
  const theme = useTheme();

  const style = {
    bgcolor: "black",
    boxShadow: 24,
    p: 4,
    minHeight: "80vh",
  };

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2"  sx={{color: "#EBD290"}}>
        Glamberry Menu - Men
      </Typography>
      <List>
        {Object.keys(services).map((category) => (
          <React.Fragment key={category}>
            <ListItem>
              <Typography variant="h6"  sx={{color: "#EBD290"}}>{category}</Typography>
            </ListItem>
            {Object.keys(services[category]).map((subCategory) => (
              <React.Fragment key={subCategory}>
                <ListItem>
                  <Typography variant="subtitle1"  sx={{color: "#EBD290"}}>{subCategory}</Typography>
                </ListItem>
                {Array.isArray(services[category][subCategory])
                  ? services[category][subCategory].map((service, index) => (
                      <ListItem key={index} sx={{ pl: 4 }}>
                        <ListItemText primary={service} sx={{color: "white"}}/>
                      </ListItem>
                    ))
                  : Object.keys(services[category][subCategory]).map(
                      (service, index) => (
                        <ListItem key={index} sx={{ pl: 4 }}>
                          <ListItemText
                            primary={services[category][subCategory][service]}
                            sx={{color: "white"}}
                          />
                        </ListItem>
                      )
                    )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default MenServices;
