import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import { FaSquareWhatsapp } from "react-icons/fa6";
import BottomBar from "./components/BottomBar/BottomBar";
import ServiceDetail from "./components/ServiceDetail";
import MenServices from "./components/MenServices";
import WomenServices from "./components/WomenServices";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/men" element={<MenServices />} />
          <Route path="/women" element={<WomenServices />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/services/:serviceId" element={<ServiceDetail />} />
          <Route path="*" element={<Home />} /> {/* Fallback route */}
        </Routes>
        <BottomBar />
      </Router>
      <div
        onClick={() => {
          let url = `https://api.whatsapp.com/send/?phone=919972839766`;
          window.open(url);
        }}
        style={{
          position: "sticky",
          bottom: 10,
          left: "95vw",
          backgroundColor: "transparent",
          maxWidth: "5em",
        }}
      >
        <FaSquareWhatsapp size="4em" color="#00d25c" />
      </div>
    </>
  );
}

export default App;
