import React from 'react';
import { TextField, styled } from '@mui/material';

export const LeftTextField = styled(TextField)(({}) => ({
    minWidth: "100%",
    marginTop: "30px",
  //  maxHeight: 30,
    // marginLeft: "5%",
    borderWidth: "4px",
    '@media (max-width: 768px)': {
      
    }
}));

export const RightTextField = styled(TextField)(({}) => ({
    minWidth: "16vw",
    marginLeft: "2.5vw",
    '@media (max-width: 768px)': {
      minWidth: "80vw", // Adjust styles for smaller screens
      marginLeft: "0",
      marginTop: 20
    }
  }));

export const FullTextField = styled(TextField)(({})=>({
    width: "100%",
    marginTop: "10px"
}));

