import React from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  styled,
} from "@mui/material";
import Location from "../components/Location";

const StyledContainer = styled(Container)({
  marginTop: "10rem",
  marginBottom: "5rem",
});

const ContactCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  margin: "10px",
  height: "100%",
  backgroundColor: "#fbf7eb"
});

const ContactCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
});

const ContactPage = () => {
  return (
    <StyledContainer maxWidth="md">
      <Typography variant="h4" gutterBottom sx={{fontFamily: "Lora"}}>
        Contact Us
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <ContactCard>
            <ContactCardContent>
              <Typography variant="h6" gutterBottom sx={{fontFamily: "Lora"}}>
                Address
              </Typography>
              <Typography variant="body1" sx={{fontFamily: "Lora"}}>
                12,1st cross, Basavanagar Main Rd, opposite shell <br /> petrol
                pump, Vibhutipura, <br /> Bengaluru, Karnataka 560037
              </Typography>
            </ContactCardContent>
          </ContactCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ContactCard>
            <ContactCardContent>
              <Typography variant="h6" gutterBottom sx={{fontFamily: "Lora"}}>
                Phone Number
              </Typography>
              <Typography variant="body1">+91-9972839766</Typography>
            </ContactCardContent>
          </ContactCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ContactCard>
            <ContactCardContent>
              <Typography sx={{fontFamily: 'Lora'}} variant="h6" gutterBottom>
                Email
              </Typography>
              <Typography sx={{fontFamily: 'Lora'}}  variant="body1">glamberry2023@gmail.com</Typography>
            </ContactCardContent>
          </ContactCard>
        </Grid>
      </Grid>
      <Location />
    </StyledContainer>
  );
};

export default ContactPage;
