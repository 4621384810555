import React from "react";
import { Box, Button, styled, Typography, Divider, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import ServiceModal from "./ServiceModal";
//img
import pexels1 from "../assets/bg8.png";
 

const Header = () => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const CustomBox = styled(Box)(({ theme }) => ({
    minHeight: "90vh",  
    minWidth: "100%",
    display: "flex",
    flexDirection: "row",
    paddingTop: "50px",
    position: "relative",  
    backgroundColor: "black",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const BoxText = styled(Box)(({ theme }) => ({
    flex: "1",
    paddingLeft: theme.spacing(8),
    paddingTop: theme.spacing(20),
    color: "#EBD290",
    [theme.breakpoints.down("md")]: {
      flex: "2",
      textAlign: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  return (
    <>
        <CustomBox component="header">
      {/*  Box text  */}
      <BoxText component="section" sx={{marginTop: "50px"}}>
        <Typography
          variant="h3"
          component="h2"
          sx={{
            fontWeight: 700,
            color: "#ffdd58",
            fontFamily: "Lora",
            minWidth: isMobile ? "initial" : 650,
            fontSize: isMobile ? "24px" : "3.75em",
          }}
        >
          {/* Todo glory check */}
          Beauty beyond borders
        </Typography>
        <Typography
          variant="p"
          component="p"
          sx={{
            py: 3,
            lineHeight: 1.6,
            fontWeight: 500,
            color: "white",
            fontFamily: "Lora",
            fontSize: isMobile ? "14px" : "1.25em",
          }}
        >
          Our committed and competent team is here to serve you. {!isMobile && <br />} Enjoy our wide range of services in a cozy and luxurious environment.          
        </Typography>
        <Button           
          size="large"
          sx={{
            fontSize: "1.1em",
            textTransform: "none !important",
            fontFamily: "Lora",
            py: 2,
            px: 4,
            mt: 6,
            mb: 2,
            borderRadius: 2,
            color: "black",
            minWidth: "60%",
            backgroundColor: "#FFDF57",
            "&:hover": {
              backgroundColor: "#FFDF57",
            },
          }}
          onClick={() => {
             handleOpen();
          }}>Explore Services</Button>
      </BoxText>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: {
            flex: "1",
            paddingTop: "30px",
            alignSelf: "center",
          },
          [theme.breakpoints.up("md")]: {
            flex: "2",
            alignSelf: "center",
            marginTop: "75px",
          },
        })}
      >
        <img
          src={pexels1}
          alt="hero-glamberry"
          style={{
            width: "95%",
          }}
        />
      </Box>
    </CustomBox>
    <ServiceModal open={open} handleOpen={handleOpen} handleClose={handleClose} />
    </>

  );
};

export default Header;
