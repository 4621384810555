import React from "react";
import BookAppointment from "../components/BookAppointment";
import Header from "../components/Header";
import GallerySalon from "../components/GallerySalon";
import ServiceLanding1 from "../components/ServiceLanding1";
import ServiceLanding2 from "../components/ServiceLanding2";
import ServiceLanding3 from "../components/ServiceLanding3";
import SocialProofComponent from "../components/SocialProof";
import { Grid, Stack } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import "./styles.css";

const Home = () => {
  return (
    <>
      <Header />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 8 }}
        justifyContent="center"
        sx={{
          py: 8,
          px: 1,
          display: { xs: "flex" },
          backgroundColor: "#fbf7eb",
          // alignItems: "flex-start"
        }}
        className="stack"
      >
        <BookAppointment />
        <GallerySalon />
      </Stack>  
      <SocialProofComponent />
      <ServiceLanding1 />
      <ServiceLanding2 />
    </>
  );
};

export default Home;
