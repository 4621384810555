import React, { useState } from "react";
import {
  Select,
  MenuItem,
  Button,
  styled,
  InputLabel,
  FormControl,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import Title from "./Title";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LeftTextField, RightTextField } from "./UIComponents";
import { Link } from "react-router-dom";
import "./styles.css";
import axios from "axios";

const StyledButton = styled(Button)({
  fontSize: "0.9rem",
  textTransform: "capitalize",
  py: 2,
  px: 4,
  mt: 6,
  mb: 2,
  borderRadius: 2,
  color: "white",
  minWidth: "100%",
  marginTop: "40px",
  backgroundColor: "#020100",
  "&:hover": {
    backgroundColor: "#040200",
  },
  "@media (max-width: 768px)": {
    maxWidth: "80vw",
    marginBottom: "50px",
    marginTop: "30px",
  },
});

const Popup = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Appointment Request Sent</DialogTitle>
    <DialogContent>
      <Typography>
        Your appointment request has been sent successfully.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button
        sx={{
          fontSize: "0.9rem",
          textTransform: "capitalize",
          py: 1,
          px: 2,
          mt: 3,
          mb: 1,
          borderRadius: 2,
          color: "white",
          marginTop: "40px",
          backgroundColor: "#020100",
          "&:hover": {
            backgroundColor: "#040200",
          },
        }}
        onClick={onClose}
        color="primary"
      >
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

const BookAppointment = () => {
  const [dummyDate, setDummyDate] = useState("");
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [service, setService] = useState("");
  const [time, setTime] = useState(new Date());
  const [showPopup, setShowPopup] = useState(false);

  const postAppointment = () => {
    const reqB = {
      name: name,
      contact: contact,
      service: service,
      time: time,
    };

    axios
      .post("https://glamberry.onrender.com/api/appointments", reqB)
      .then((res) => {
        console.log("res: ", res);
        setShowPopup(true);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  return (
    <Stack
      component="section"
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        backgroundColor: "#fbf7eb",
        padding: "5vw",
        paddingTop: "0vw",
      }}
      id="book-appointment"
    >
      <Title text={"Book Appointment"} alignSelf="center" />
      <LeftTextField
        label="Your Name"
        onChange={(ev) => {
          console.log("text: ", ev?.target?.value);
          setName(ev?.target?.value);
        }}
      />
      <LeftTextField
        label="Your Phone"
        onChange={(ev) => {
          console.log("text: ", ev?.target?.value);
          setContact(ev?.target?.value);
        }}
      />
      <div
        className="formCustom resFlex"
        style={{ alignItems: "center", minWidth: "35vw" }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]} sx={{ minWidth: "100%" }}>
            <DatePicker
              defaultValue="Select Date"
              value={dummyDate}
              onChange={(newValue) => {
                console.log("newValue: ", newValue?.$d, "---", newValue?.d);
                setTime(newValue?.$d);
              }}
              slotProps={{ textField: { fullWidth: true, error: false } }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </div>
      <StyledButton
        variant="contained"
        size="large"
        onClick={() => {
          setShowPopup(true);
          postAppointment();
        }}
      >
        Book Now
      </StyledButton>
      <Popup open={showPopup} onClose={() => setShowPopup(false)} />
    </Stack>
  );
};

export default BookAppointment;
