import { Typography } from "@mui/material";
import React from "react";

const Title = ({ text, textAlign, alignSelf }) => {
  return (
    <Typography
      variant="h4"
      component="h3"
      sx={{
        fontWeight: "700",
        textAlign: textAlign,
        color: "#040200",
        fontFamily: "Lora",
        alignSelf: alignSelf
      }}
    >
      {text}
    </Typography>
  );
};

export default Title;
