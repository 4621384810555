import React from "react";
import {
  Typography,
  Avatar,
  Card,
  CardContent,
  Grid,
  Rating,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import supreetha from "../assets/supreetha.png";
import savitha from "../assets/savitha.png";
import usha from "../assets/usha.png";

const SocialProofComponent = () => {
  const reviews = [
    {
      id: 1,
      name: "Usha Nagesh",
      review:
        "Everything well organized and maintained good hygiene...Thank you for the hair  smoothing  I really felt happy . N my hair looks awesome  . thank uuuuuu",
      avatar: usha,
      rating: 5,
    },
    {
      id: 2,
      name: "Savitha Kondanda",
      review:
        "A very good family saloon providing excellent service, good ambience and maintaining good hygiene.",
      avatar: savitha,
      rating: 5,
    },
    {
      id: 3,
      name: "Supreetha Natraj",
      review:
        "The place is very nice and service is great. Please do visit the salon",
      avatar: supreetha,
      rating: 5,
    },
  ];

  const CustomIcon = ({ filled, ...props }) => {
    return filled ? (
      <StarIcon sx={{ color: "#ffdd58" }} {...props} />
    ) : (
      <StarIcon {...props} />
    );
  };

  return (
    <div
      style={{ padding: "20px", backgroundColor: "#fbf7eb",paddingTop: "60px",  }}
    >
      <Grid container spacing={2}>
        {reviews.map((review) => (
          <Grid item xs={12} md={4} key={review.id}>
            <Card sx={{ minHeight: "190px", backgroundColor: "black" }}>
              <CardContent
                sx={{ position: "relative", justifyContent: "space-evenly" }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    minHeight: "110px",
                    fontFamily: "Lora",
                    color: "#fbf7eb",
                    fontSize: "1em",
                  }}
                >
                  {review.review}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Avatar alt={review.name} src={review.avatar} />
                  <Typography
                    variant="p"
                    sx={{
                      marginLeft: "4px",
                      fontFamily: "Lora",
                      fontSize: "1em",
                      color: "#fbf7eb",
                    }}
                  >
                    {review.name}
                  </Typography>
                  <Rating
                    name="read-only"
                    value={review.rating}
                    readOnly
                    icon={<CustomIcon />}
                    sx={{ marginLeft: "4px" }}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default SocialProofComponent;
