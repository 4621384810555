import React from 'react';
import { Typography, Card, CardContent, CardMedia, styled } from '@mui/material';

const ServiceCard = ({ imageUrl, title, description }) => {

  const StyledCard = styled(Card)(({ theme }) => ({
    marginTop: '10rem',
    marginBottom: '5rem',
    paddingLeft: "5rem",
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: "center"
    },
  }));

  const StyledCardContent = styled(CardContent)({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '1rem',
  });

  const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
    width: '100%',
    minWidth: 200,
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  }));

  return (
    <StyledCard>
      <StyledCardMedia
        component="img"
        image={imageUrl}
        alt={title}
      />
      <StyledCardContent>
        <Typography variant="h6" component="div" gutterBottom sx={{fontFamily: 'Lora'}} >
          {title}
        </Typography>
        <Typography variant="body1" component="div" sx={{fontFamily: 'Lora'}} >
          {description}
        </Typography>
      </StyledCardContent>
    </StyledCard>
  );
};

export default ServiceCard;
